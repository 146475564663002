<template>
  <div class="flex flex-col items-center justify-center">
    <div class="flex justify-center py-3 logo-container w-full">
      <img
        :src="require('../../../../assets/images/fcLOgo.svg')"
        alt="Fresh Clinics"
        width="251"
        height="56"
        @click="gotoFcSite()"
      />
    </div>
    <div class="w-11/12 lg:w-3/12 mx-auto my-8 px-8 pt-8 pb-16 rounded-lg bg-login login-tabs-container">
      <div>
        <div class="vx-card__title mb-8">
          <h4 class="form-heading">Update Password 🔐</h4>
          <p>
            Your new password must be different from previously used passwords
          </p>
          <vs-alert
            :color="alertColor"
            :active.sync="showDismissibleAlert"
            closable
            icon-pack="feather"
            close-icon="icon-x"
            >{{ message }}</vs-alert
          >
        </div>
        <form>
          <span class="form-label">New Password</span>
          <vs-input
            :success="!errors.first('newPassword') && user.password != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('newPassword') ? true : false"
            name="newPassword"
            :danger-text="errors.first('newPassword')"
            class="w-full mb-5"
            v-validate="{
              required: true,
              min: 8,
              regex: /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/,
            }"
            ref="password"
            data-vv-as="new password"
            icon-no-border
            icon-pack="feather"
            autocomplete="new-password"
            v-model="user.password"
            :icon="passwordIcon"
            :type="passwordType"
            @click.native="showHidePassword($event)"
          />
          <span class="form-label">Confirm Password</span>
          <vs-input
            :success="
              !errors.first('confirmNewPassword') && user.confirmPassword != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('confirmNewPassword') ? true : false"
            name="confirmNewPassword"
            data-vv-as="confirm password"
            :danger-text="errors.first('confirmNewPassword')"
            class="w-full mb-5"
            v-validate="'required|confirmed:password'"
            icon-no-border
            icon-pack="feather"
            autocomplete="new-password"
            v-model="user.confirmPassword"
            :icon="confirmPasswordIcon"
            :type="confirmPasswordType"
            @click.native="showHideConfirmPassword($event)"
          />
        </form>
        <div class="my-6 cursor-pointer">
          <vs-button
            class="w-full"
            :disabled="!validateForm"
            @click="resetPassword"
            >Set New Password</vs-button
          >
        </div>
        <div
          @click="login()"
          class="flex items-center justify-center cursor-pointer"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.0833 14.4167C13.4167 14.75 13.4167 15.25 13.0833 15.5833C12.9167 15.75 12.75 15.8333 12.5 15.8333C12.25 15.8333 12.0833 15.75 11.9167 15.5833L6.91667 10.5833C6.58334 10.25 6.58334 9.75 6.91667 9.41667L11.9167 4.41666C12.25 4.08333 12.75 4.08333 13.0833 4.41666C13.4167 4.75 13.4167 5.25 13.0833 5.58333L8.66667 10L13.0833 14.4167Z"
              fill="black"
            />
            <mask
              id="mask0_1025_108583"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="6"
              y="4"
              width="8"
              height="12"
            >
              <path
                d="M13.0833 14.4167C13.4167 14.75 13.4167 15.25 13.0833 15.5833C12.9167 15.75 12.75 15.8333 12.5 15.8333C12.25 15.8333 12.0833 15.75 11.9167 15.5833L6.91667 10.5833C6.58334 10.25 6.58334 9.75 6.91667 9.41667L11.9167 4.41666C12.25 4.08333 12.75 4.08333 13.0833 4.41666C13.4167 4.75 13.4167 5.25 13.0833 5.58333L8.66667 10L13.0833 14.4167Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_1025_108583)">
              <rect width="20" height="20" fill="#47E7B8" />
            </g>
          </svg>

          <a class="px-2 w-full md:w-auto">Back To Login</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
// For custom error message
import { Validator } from "vee-validate";
const dict = {
  custom: {
    password: {
      required: "Please enter your password",
      min: "Password must be at least 6 characters",
      regex: "Must have at least one number and one uppercase letter",
    },
    confirmPassword: {
      required: "Please enter your confirm password",
      confirmed: "The confirmation password do not match",
    },
  },
};
// register custom messages
Validator.localize("en", dict);

export default {
  data() {
    return {
      user: {
        password: "",
        confirmPassword: "",
        token: this.$route.params.token,
      },
      showDismissibleAlert: false,
      message: "",
      alertColor: "primary",

      passwordType: "password",
      passwordIcon: "icon icon-eye-off",
      confirmPasswordType: "password",
      confirmPasswordIcon: "icon icon-eye-off",
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.user.password != "" &&
        this.user.password == this.user.confirmPassword
      );
    },
  },
  methods: {
    ...mapActions("users", ["resetPassword"]),
    gotoFcSite(){
      window.open(process.env.VUE_APP_FRESHCLINICS_V2_SITE)
    },
    login() {
      this.displayLoader();
      this.$router.push("/login");
    },
    displayLoader() {
      this.$vs.loading();
      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
    },
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    showHideConfirmPassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.confirmPasswordType === "password"
          ? (this.confirmPasswordType = "text")
          : (this.confirmPasswordType = "password");
        this.confirmPasswordIcon === "icon icon-eye-off"
          ? (this.confirmPasswordIcon = "icon icon-eye")
          : (this.confirmPasswordIcon = "icon icon-eye-off");
      }
      return true;
    },
    resetPassword() {
      this.$vs.loading();
      const payload = {
        userDetails: this.user,
      };
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store
            .dispatch("auth/resetPassword", payload)
            .then((response) => {
              this.$vs.loading.close();
              this.$router.push("/success");
            })
            .catch((error) => {
              this.alertColor = "danger";
              this.showDismissibleAlert = true;
              this.message = error.message;
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: error.message,
                // iconPack: "feather",
                // icon: "icon-alert-circle",
                color: "danger",
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.login-tabs-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}

.form-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #0e4333;
}

.logo-container {
  border-bottom: 2px solid #ebe9f1;
}
</style>
